var products_loading = false;
var products_loaded = false;
var init = false;
var xhr = null;

$(document).ready(function(){
	$('[data-pimcore-component="products-filter"] input:not([type="search"]), [data-pimcore-component="products-filter"] select').change(update_filter);

	var timeout = null;
	$('[data-pimcore-component="products-filter"] input[type="search"]').keyup(function(){
		if (timeout != null) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(function(){
			$('form[data-pimcore-component="products-filter"] input[type="checkbox"]').prop('checked', false);
			$('form[data-pimcore-component="products-filter"] select').val('');
			$('form[data-pimcore-component="products-filter"] input[data-component="filter-theme"]').val('');
			update_filter();
		}, 500);
	});

	$('[data-pimcore-component="products"]').each(function(){
		//Load ajax products of category
		$(this).attr('data-page', 1),
		update_filter();
	});

	if ($('.navbar.navbar--filter').length > 0) {
		if ($(window).width() >= 768) {
			scroll_filter();
		}
		$(window).resize(function(){
			if ($(window).width() < 768 ) {
				$filter = $('.navbar.navbar--filter > aside.filters');
				$filter.css('margin-top', 0);
				$filter.css('height', 'auto');
				clearInterval(scroll_filter_timer);
			}else{
				scroll_filter();
			}
		});
	}

});

var scroll_filter_timer = null;
function scroll_filter(){
	if (scroll_filter_timer != null) {
		clearInterval(scroll_filter_timer);
	}

	$filter_container = $('.navbar.navbar--filter');
	$filter = $('.navbar.navbar--filter > aside.filters');
	$products_container = $('.products');

	$filter.css('height', 'auto');

	var filter_max_height = $(window).outerHeight() - 40;
	var filter_top_pos = $filter_container.offset().top;
	var filter_height = $filter.outerHeight();

	scroll_filter_timer = setInterval(function(){

		var products_height = $products_container.outerHeight();

		if ($(document).scrollTop() > filter_top_pos && products_height > filter_height) {
			var margin_top = (($(document).scrollTop()-filter_top_pos)+20);
			if ((margin_top + filter_max_height + 20) < products_height) {
				if ($filter.attr('scrolling') != 'scrolling') {
					$filter.attr('scrolling', 'scrolling');
					$filter.css('transition', '1s all');
					$filter.css('height', filter_max_height+'px');
				}
				$filter.css('margin-top', margin_top +'px');
			}else if((products_height-filter_max_height-20) > 0){
				if ($filter.attr('scrolling') != 'none') {
					$filter.attr('scrolling', 'none');
					$filter.css('transition', 'none');
					$filter.css('height', 'auto');
					$filter.css('margin-top', (products_height-$filter.outerHeight()-20) +'px');
				}
			}
		}else if($filter.attr('scrolling') != 'top'){
			$filter.attr('scrolling', 'top');
			$filter.css('margin-top', 0);
			$filter.css('height', 'auto');
			$(document).scrollTop( filter_top_pos-50 );
		}

	}, 200);
}

function update_filter(){
		if (xhr != null) {
			xhr.abort();
		}
		$container = $('[data-pimcore-component="products"]').eq(0);

		var filter_params = $('[data-pimcore-component="products-filter"]').serializeJSON();
		var filters = JSON.parse(filter_params);

		for (var param in filters) {
			if (filters[param] == '') {
				delete filters[param];
			}else if(typeof filters[param] == 'object'){
				if(filters[param].filter){
					var filtered = filters[param].filter(function (el) {
					  return el != '';
					});
					filters[param] = filtered;
					if (filtered.length == 0) {
						delete filters[param];
					}
				}
			}
		}

		$('.filter-input-collection').each(function(){
			var selects = 0;
			var total = 0;
			$('.filter-subcollection', $(this).parent()).each(function(){
				$('.filter-input-subcollection', this).each(function(){
					total++;
					if ($(this).is(':checked')) {
						selects++;
					}
				});
				if (selects > 0) {
					$('.filter-expand', this).removeClass('collapsed').attr('aria-expanded', 'true');
					$('.filter-expand + collapse', this).addClass('show');
				}
			});



			if (selects > 0) {
				$(this).prop('checked', true);
			}else{
				$(this).prop('checked', false);
			}
		});

		var params_url = jQuery.param( filters );
		filter_params = JSON.stringify(filters);
		$container.attr('data-filter', filter_params);
		var page_url = location.protocol + '//' + location.host + location.pathname;

		$('.breadcrumb .breadcrumb-subcollection, .breadcrumb .breadcrumb-theme').remove();
		if (filters.o_theme_path) {
			var parts = filters.o_theme_path[0].split("/");
			$('.breadcrumb').append('<a class="breadcrumb-item breadcrumb-subcollection" href="javascript:;">'+parts[parts.length - 2]+'</a><span class="breadcrumb-item breadcrumb-theme active">'+parts[parts.length - 1]+'</span>');
			$('.breadcrumb .breadcrumb-subcollection').click(function(){
				$('input[data-component="filter-theme"]').val('');
				update_filter();
			});
		}

		if (params_url != '') {
			window.history.pushState("", "", page_url+'?'+params_url);
		}else{
			window.history.pushState("", "", page_url);
		}

		$container.find('.wrapper').html('');
		$container.attr('data-page', 1);
		products_loaded = false;
		load_category_products($container);
}

function init_scroll(){
	init = true;
	if ($('[data-pimcore-component="products"]').length > 0) {
		$container = $('[data-pimcore-component="products"]').eq(0);
		$(window).scroll(function() {
			if(($(window).scrollTop() + $(window).height()) > ($container.offset().top + $container.outerHeight()) && products_loading == false && products_loaded == false) {
		        //products_loading = true;
				var page = parseInt($container.attr('data-page')) + 1;
				$container.attr('data-page', page),
				load_category_products($container);
		    }
		});
	}
}

function load_category_products(element){
	if (xhr != null) {
		xhr.abort();
	}
	if (products_loaded == false) {
		products_loading = true;
		$('.products-empty', element).hide();
		$('.products-loader', element).show();
		if ($('.subcategory-title', element).length > 0) {
			var last_parent = $('.subcategory-title', element).last().attr('data-id');
		}else{
			var last_parent = 0;
		}
		xhr = $.ajax({
			method: "POST",
			url: "/ajax/pimcore/1/"+lang,
			data: {category: $(element).attr('data-pimcore-category'), filter: $(element).attr('data-filter'), page: $(element).attr('data-page'), last_parent: last_parent},
			headers: {
	       		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	    	}
		}).done(function( result ) {
			if (result == '') {
				if ($('.wrapper', element).html() == '') {
					$('.products-empty', element).show();
				}
				products_loaded = true;
			}
			if (init == false) {
				init_scroll();
			}
			xhr = null;

			let $result = $(result);
			$result.find('.MagicZoom').each((i, el) => {
				MagicZoom.start(el);
			});

			$('.wrapper', element).append($result);

			products_loading = false;
			$('.products-loader', element).hide();
		});
	}
}