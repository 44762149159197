jQuery(function ($) {

	loadWebshopComponents();

	$(document).on('submit', 'form.ajax-form', function (e) {
		e.preventDefault(); // avoid to execute the actual submit of the form.

		var form = $(this);
		var url = form.attr('action');

		let xhr = $.ajax({
			method: "POST",
			url: url,
			data: form.serialize(),
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		}).done(function (result) {
			new Noty({
				type: 'success',
				timeout: 2000,
				closeWith: ['click', 'button'],
				text: result.message,
			}).show();

			$('#amount_' + result.id).val(1);
			$('#amount_' + result.id).attr('max', $('#amount_' + result.id).attr('max') - result.amount);

			form.find('button[type="submit"]').removeClass('btn-primary').addClass('btn-secondary');

			loadWebshopComponents();

		}).catch(() => {
			new Noty({
				type: 'error',
				timeout: 2000,
				closeWith: ['click', 'button'],
				text: 'Fout bij het toevoegen aan winkelmandje!',
			}).show();
		});

		return false;
	});

	function loadWebshopComponents(){
		$('[data-component="basket-dropdown"]').load("/" + lang + "/webhop/basket/dropdown");
		$('[data-component="basket-counter"]').load("/" + lang + "/webhop/basket/count");
		
		if ($('[data-component="basket-total-rolls"]').length || $('[data-component="basket-total-m"]').length) {
			$.ajax({
				method: "GET",
				url: '/' + lang + '/webhop/basket',
			}).done(function (result) {
				let basket = result.basket;
				$('[data-component="basket-total-rolls"]').html(basket.total_rolls);
				$('[data-component="basket-total-m"]').html(basket.total_m);
			});
		}
		

		$('[data-component="basket-amount"]').off('change', basketAmountChanged);
		$('[data-component="basket-amount"]').on('change', basketAmountChanged);
		$('[data-component="basket-delete"]').off('click', removeFromBasket);
		$('[data-component="basket-delete"]').on('click', removeFromBasket);
	}

	function basketAmountChanged(){

		let id = $(this).attr('data-id');

		let value = parseInt($(this).val());
		let max = parseInt($(this).attr('max'));

		if (value <= max) {
			let xhr = $.ajax({
				method: "POST",
				url: '/' + lang + '/webhop/basket/update/' + id,
				data: {
					amount: value
				},
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				}
			}).done(function (result) {
				loadWebshopComponents();
			});
		}else{
			addAmount(this, max);
		}
	}

	function removeFromBasket() {

		let id = $(this).attr('data-id');

		let xhr = $.ajax({
			method: "POST",
			url: '/' + lang + '/webhop/basket/update/' + id,
			data: {
				amount: null
			},
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		}).done(function (result) {
			loadWebshopComponents();
			$('[data-component="product-row"][data-id="'+result.id+'"]').fadeOut(() => {
				$(this).remove();
			});
		});
	}
	
});

function addAmount(element_selector, amount) {
	let $element = $(element_selector);

	let current = parseInt($element.val());
	let val = current + amount;

	let max = $element.attr('max');

	if (val < 1) {
		val = 1;
	}else if (val > max) {
		val = max;
	}

	$element.val(val);

	if (current != val) {
		console.log('trigger add amount');
		$element.trigger('change');
	}

	
}