// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");

jQuery(function () {
	$('.photoslider').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: false
	});

	$('.loginslider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
		prevArrow: '<button type="button" class="slick-prev text-center" style="font-size:3rem;margin-top:-20px;"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next text-center" style="font-size:3rem;margin-top:-20px;"><i class="icon-angle-right"></i></button>',
	});

	if ($('.header-fixed').length > 0){
		let $topbar = $('.header-fixed .topbar');
		
		setInterval(() => {
			if (document.documentElement.scrollTop > 50) {
				if (!$topbar.hasClass('fixed')) {
					let height = $topbar.outerHeight();
					$('body').css('padding-top', height+'px');
					$topbar.addClass('fixed');
				}
			}else{
				if ($topbar.hasClass('fixed')) {
					$('body').css('padding-top', 0);
					$topbar.removeClass('fixed');
				}
			}
		}, 200);
	}

	var options = {
		url: "/search/products_" + $('meta[name="site-lang"]').attr('content') + ".json",
		getValue: "code",
		list: {
			match: {
				enabled: true
			},
			maxNumberOfElements: 8
		},
		template: {
			type: "custom",
			method: function (value, item) {
				return "<a href=\"" + item.url + "\"><img src=\"" + item.image + "\" class=\"img-fluid\"/>" + value + '</a>';
			}
		},
	};

	$("#search-af").easyAutocomplete(options);

	var lightbox = $('#blueimp-gallery-carousel-link').simpleLightbox({
		loop: false,
	});

	if ($('#links').length > 0) {
		blueimp.Gallery.prototype.options.toggleControlsOnReturn = false;
		blueimp.Gallery.prototype.options.toggleControlsOnSlideClick = false;
		var gallery = blueimp.Gallery(
			document.getElementById('links').getElementsByTagName('a'), {
				container: '#blueimp-gallery-carousel',
				carousel: true,
				startSlideshow: false,
				thumbnailIndicators: false,
				youTubeClickToPlay: false,
				hidePageScrollbars: true,
				onslide: function (index, slide) {
					$('#blueimp-gallery-carousel-link').attr('href', $('#links.product-img__thumbs a').eq(index).attr('href'));
					$('#links.product-img__thumbs a').removeClass('active');
					$('#links.product-img__thumbs a').eq(index).addClass('active');
				}
			}
		);
	}

	$('[data-toggle="tooltip"]').tooltip();

	$('#links.product-img__thumbs a').each(function (i, el) {
		$(el).click(function () {
			gallery.slide($(this).index(), 0);
			return false;
		});
	});

	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers: {
			media: {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click(startVideo);
	$(".rc-video__overlay").not('.lightbox-media').click(startVideo);

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0', 'autoplay=1'));
		} else {
			if (src.indexOf('?') > -1) {
				iframe.attr('src', src + '&autoplay=1');
			} else {
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();

	// Related products slider
	$('.related-products').slick({
	  dots: false,
	  infinite: true,
	  speed: 300,
	  prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
	  nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
	  slidesToShow: 5,
	  slidesToScroll: 1,
	  responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 3,
	        infinite: true,
	        dots: true
	      }
	    },
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});

	$('.diffrent-colorways').slick({
		dots: false,
		infinite: true,
		speed: 300,
		prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	// Product grid
	/*$('.products').isotope({
	  percentPosition: true,
	  itemSelector: '.product-item',
	  masonry: {
	    columnWidth: '.product-sizer',
	    gutter: 0
	  }
	});*/
});
